<template>
  <v-dialog v-model="visible" :width="'450px'" max-width="90%">
    <template #activator="{on}">
      <v-btn v-if="!isUpdate" v-on="on" color="primary" outlined @click="toggle"
        ><v-icon left>mdi-plus</v-icon>Thêm banner</v-btn
      >
      <v-btn v-else color="primary" icon @click="toggle">
        <v-icon>mdi-border-color</v-icon>
      </v-btn>
    </template>

    <v-card flat>
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        flat
        height="56x"
      >
        <v-toolbar-title v-if="isUpdate" class="font-weight-bold">
          Cập nhật banner cho {{ selectedCollection.name }}
        </v-toolbar-title>
        <v-toolbar-title v-else class="font-weight-bold">
          Thêm mới banner cho {{ selectedCollection.name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="toggle">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-form ref="collectionTypeForm" lazy-validation>
        <div class="pa-5">
          <div class="font-weight-bold mb-1">Banner</div>

          <tp-input-image
            :acceptedFileTypes="['image/*']"
            :maxFileSize="500 * 1024"
            :src.sync="banner.image"
            allowFileSize
            class="flex-grow-1"
            required
            style="max-width: 200px; max-height: 200px; margin-bottom: 20px"
          ></tp-input-image>

          <div class="font-weight-bold mb-1">Tên banner</div>

          <tp-text-field
            v-model="banner.name"
            placeholder="Nhập tên cho banner"
            validate="required"
          ></tp-text-field>

          <div class="font-weight-bold mb-1">Url</div>

          <tp-text-field
            v-model="banner.url"
            placeholder="Nhập link cho banner"
            validate="url"
          ></tp-text-field>
        </div>
      </v-form>

      <div class="px-5 pb-5 d-flex justify-end">
        <v-btn
          class="grey lighten-5 rounded-lg mr-2"
          color="grey--text text--darken-1"
          text
          @click="resetCollection()"
        >
          Hủy
        </v-btn>

        <v-btn
          :loading="
            statusRequest === 'loading-updateCollection' || loadingUpload
          "
          class="cyan lighten-5 rounded-lg"
          color="primary"
          text
          @click="handleSave"
        >
          Lưu
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, getCurrentInstance, computed, watchEffect } from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { cloneDeep } from "@thinkvn/utils";
import { toFormData } from "@vt7/utils";
import MediaService from "@/core/service/media.service";
import { showModalAlertError } from "@/core/composables";
import { createVuexHelpers } from "@/core/composables";

export default {
  data() {
    return {};
  },

  props: {
    selectedCollection: {
      type: Object,
      default: () => {}
    },
    selectedBanner: {
      type: Object,
      default: () => {}
    }
  },

  setup(props) {
    const { visible, toggle } = useVisible();
    const instance = getCurrentInstance();
    const cloneCollection = ref({});
    const banner = ref({
      image: "",
      url: "",
      name: ""
    });
    const loadingUpload = ref(false);
    const collectionTypeForm = ref(null);

    const { useGetters, useActions } = createVuexHelpers();
    const { statusRequest } = useGetters("COLLECTION", ["statusRequest"]);
    const { updateCollection } = useActions("COLLECTION", ["updateCollection"]);

    const isUpdate = computed(
      () => props.selectedBanner && !!props.selectedBanner.id
    );

    const handleSave = async () => {
      const isValidate = await collectionTypeForm.value.validate();

      if (!isValidate) return;

      if (banner.value.image instanceof File) {
        try {
          loadingUpload.value = true;
          const { data } = await MediaService.uploadImage(
            toFormData({
              file: banner.value.image,
              folder: "core/collection"
            })
          );

          banner.value.image = data;
        } catch (error) {
          loadingUpload.value = false;
          showModalAlertError(this, {
            title: "Lỗi tải lên ảnh thumbnail",
            message: error
          });

          return;
        } finally {
          loadingUpload.value = false;
        }
      }

      try {
        if (!isUpdate.value) {
          await updateCollection({
            ...cloneCollection.value,
            banners: [...(cloneCollection.value.banners || []), banner.value]
          });
        } else {
          const banners = cloneCollection.value.banners.map(item => {
            if (item.id === props.selectedBanner.id) {
              return banner.value;
            }

            return item;
          });

          await updateCollection({
            ...cloneCollection.value,
            banners
          });
        }

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm banner thành công"
          }
        });
      } catch (error) {
        console.log("error", error);
        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm banner thất bại"
          }
        });
      } finally {
        toggle();
      }
    };

    const resetCollection = () => {
      toggle();
    };

    watchEffect(() => {
      if (isUpdate.value) {
        banner.value = { ...props.selectedBanner };
      } else {
        banner.value = { image: "", url: "", name: "" };
      }
    });

    watch(
      () => props.selectedCollection,
      newValue => {
        if (!newValue.id) return;
        cloneCollection.value = cloneDeep(newValue);
      },
      { deep: true, immediate: true }
    );

    watch(
      () => visible.value,
      newValue => {
        if (!newValue) {
          collectionTypeForm.value.resetValidation();

          if (isUpdate.value) {
            banner.value = { ...props.selectedBanner };
          } else {
            banner.value = { image: "", url: "", name: "" };
          }
        }
      }
    );

    return {
      visible,
      toggle,
      handleSave,
      cloneCollection,
      banner,
      resetCollection,
      statusRequest,
      collectionTypeForm,
      loadingUpload,
      isUpdate
    };
  }
};
</script>
