<template>
  <v-card v-if="selectedCollection && selectedCollection.id" class="pa-5" flat>
    <div class="font-weight-bold text-h5 mb-4">
      {{ selectedCollection.name }}
    </div>

    <button-add-banner
      :selectedCollection="selectedCollection"
    ></button-add-banner>

    <table class="table collection-banner-table">
      <thead class="thead-dark">
        <th width="50"></th>
        <th>Banner</th>
        <th>Tên Banner</th>
        <th>Url</th>
        <th>Hành động</th>
      </thead>

      <draggable
        v-if="selectedCollection.banners && selectedCollection.banners.length"
        v-model="selectedCollection.banners"
        tag="tbody"
        @end="drag = false"
        @start="drag = true"
        @change="sortList(selectedCollection.banners)"
      >
        <tr v-for="(banner, index) in selectedCollection.banners" :key="index">
          <td>
            <div>
              <svg
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
                width="20"
              >
                <path
                  d="M5.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 4.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm1.5 3a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0ZM10.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3ZM12 8a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Zm-1.5 6a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </td>

          <td>
            <v-img
              :aspect-ratio="16 / 9"
              :src="banner.image"
              lazy-src="https://picsum.photos/id/11/100/60"
              width="150"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
          </td>

          <td>
            <div>
              {{ banner.name }}
            </div>
          </td>

          <td>
            <div>
              <a
                :href="banner.url"
                target="_blank"
                style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: block;"
                >{{ banner.url }}</a
              >
            </div>
          </td>

          <td>
            <div class="d-flex align-center justify-center">
              <div @click="handleDeleteBanner(banner)">
                <v-btn color="error" icon>
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
              <div>
                <button-add-banner
                  :selectedBanner="banner"
                  :selectedCollection="selectedCollection"
                ></button-add-banner>
              </div>
            </div>
          </td>
        </tr>
      </draggable>

      <tbody v-else>
        <tr>
          <td colspan="5" class="text-center py-3">
            Không có banner nào.
          </td>
        </tr>
      </tbody>
    </table>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import ButtonAddBanner from "./ButtonAddBanner.vue";

export default {
  name: "SelectedCollection",
  components: {
    draggable,
    ButtonAddBanner
  },
  props: {
    selectedCollection: {
      type: Object,
      default: null
    }
  },

  data() {
    return {};
  },

  computed: {
    statusRequest() {
      return this.$store.getters["COLLECTION/statusRequest"];
    }
  },

  methods: {
    async deleteBanner(banner) {
      const newBanners = this.selectedCollection.banners.filter(
        item => item.id !== banner.id
      );

      try {
        await this.$store.dispatch("COLLECTION/updateCollection", {
          ...this.selectedCollection,
          banners: newBanners
        });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Xóa banner thành công"
          }
        });
      } catch (error) {
        console.log(error);
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Xóa banner thất bại"
          }
        });
      }
    },

    handleDeleteBanner(banner) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "Xóa banner",
          message: "Bạn có chắc chắn muốn xóa banner này không?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.deleteBanner(banner);
            }
          }
        }
      });
    },

    async sortList(banners) {
      try {
        await this.$store.dispatch("COLLECTION/updateCollection", {
          ...this.selectedCollection,
          banners
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss">
.collection-banner-table {
  width: 100%;
  border-collapse: separate !important;
}

.collection-banner-table th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
