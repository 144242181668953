<template>
  <div>
    <v-row class="pl-5">
      <v-col cols="6" class="overflow-hidden py-0 d-flex flex-column">
        <!-- Start: Top actions -->
        <div class="d-flex justify-space-between">
          <top-action
            :search-key="searchKey"
            @updateSearchKey="updateSearchKey($event)"
          ></top-action>
          <!-- End: Top actions -->
        </div>
      </v-col>
    </v-row>

    <v-row class="pl-5">
      <v-col cols="4" class="py-0">
        <div class="mr-5 p-4 bg-white collections-container">
          <collection-list
            :search-key="searchKey"
            @updateSelectedCollectionId="selectedCollectionId = $event"
          ></collection-list>
        </div>
      </v-col>

      <v-col cols="8" class="py-0">
        <div class="collections-container">
          <selected-collection
            :selectedCollection="selectedCollection"
          ></selected-collection>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopAction from "./components/TopAction";
import CollectionList from "./components/CollectionList";
import SelectedCollection from "./components/SelectedCollection";

export default {
  components: {
    TopAction,
    CollectionList,
    SelectedCollection
  },

  data() {
    return {
      searchKey: null,
      selectedCollectionId: null
    };
  },

  async created() {
    await this.$store.dispatch("COLLECTION/getCollections");
  },

  methods: {
    async updateSearchKey(value) {
      this.searchKey = value;
      await this.$store.dispatch("COLLECTION/getCollections", {
        search: this.searchKey
      });
    }
  },

  computed: {
    collections() {
      return this.$store.getters["COLLECTION/collections"];
    },

    selectedCollection() {
      return this.collections.find(
        collection => collection.id === this.selectedCollectionId
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.collections-container {
  overflow-x: hidden;
  height: calc(100vh - 64px - 53px - 40px - 44px);
}
</style>
