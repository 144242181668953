<template>
  <div>
    <div v-if="statusRequest.value === 'loading-getCollections'">
      <v-overlay>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </div>
    <v-list v-else dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="collection in collections"
          :key="collection.id"
          @click="handleSelectCollection(collection.id)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-body"
                >{{ collection.name }}
                <span v-if="collection.banners && collection.banners.length"
                  >({{ collection.banners.length }} banner)</span
                >
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "CollectionList",
  data() {
    return {
      selectedItem: null
    };
  },

  computed: {
    collections() {
      return this.$store.getters["COLLECTION/collections"];
    },
    statusRequest() {
      return this.$store.getters["COLLECTION/statusRequest"];
    }
  },

  methods: {
    handleSelectCollection(id) {
      this.$emit("updateSelectedCollectionId", id);
    }
  }
};
</script>
