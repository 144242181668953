<template>
  <div class="mr-5 mb-4 w-full">
    <tp-search-option
      :options="productSearchOptions"
      :value="searchKey"
      @search="updateSearchKey($event)"
    ></tp-search-option>
  </div>
</template>

<script>
export default {
  props: {
    searchKey: {
      type: null
    }
  },
  data() {
    return {
      productSearchOptions: [{ type: "search", label: "Tìm kiếm theo tên" }]
    };
  },
  methods: {
    updateSearchKey(value) {
      this.$emit("updateSearchKey", value);
    }
  }
};
</script>
